import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import back from "../images/bus_back_banner.png"
const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div style={{ backgroundImage: `url(${back})`, backgroundSize: 'cover', backgroundRepeat: 'repeat', width: '100%', height: '100%' }}>

    <h1>404: Not Found</h1>
    <p>There's nothing here.</p>
    </div>
  </Layout>
)

export default NotFoundPage
